import { css } from "@emotion/core";
import { FormattedHTMLMessage, Link } from "gatsby-plugin-intl";
import RightArrow from "../../svgs/icons/right-arrow.svg";
import React from "react";
import { Desktop, Tablet } from "../../constants/responsive";
import { useStaticQuery, graphql } from "gatsby";

const BlogList = ({ all }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulBlogPost {
        edges {
          node {
            identifier
            title
            thumbnail {
              file {
                url
              }
            }
            description {
              description
            }
            id
            date
            content {
              raw
            }
          }
        }
      }
    }
  `);
  const allNews = data.allContentfulBlogPost.edges
    .map((node) => node.node)
    .sort((a, b) => {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      return 0;
    });

  const news = all ? allNews : allNews.slice(0, 3);
  return (
    <div
      id="blog"
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        scroll-margin-top: 70px;

        padding-top: ${all ? 0 : "20px"};
        ${Tablet} {
          padding-top: ${all ? 0 : "40px"};
        }
        ${Desktop} {
          padding-top: ${all ? 0 : "60px"};
        }
      `}
    >
      <div
        css={css`
          width: 100%;
          min-width: 280px;
          max-width: 400px;
          ${Tablet} {
            min-width: none;
            max-width: none;
            width: 100%;
          }
        `}
      >
        <div
          css={css`
            margin-bottom: 30px;
            width: 100%;
            ${Tablet} {
              margin-bottom: 40px;
            }
            ${Desktop} {
              margin-bottom: 60px;
            }
          `}
        >
          <h1
            css={css`
              display: block;
              margin: 0px;
              text-align: center;
            `}
          >
            <FormattedHTMLMessage id={`blog.${all ? "title" : "fresh"}`} />
          </h1>
        </div>
        <div
          css={css`
            display: flex;
            width: 100%;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-bottom: 0px;
          `}
        >
          {news.map((post, index) => {
            return (
              <Link
                key={post.id}
                to={`/blog/${post.identifier}`}
                css={css`
                  color: black !important;
                  width: 100%;
                  margin-bottom: 60px;

                  ${Tablet} {
                    width: 340px;
                  }
                  cursor: pointer;
                  display: ${index < 1 || all ? "block" : "none"};
                  ${Tablet} {
                    display: ${index < 2 || all ? "block" : "none"};
                  }
                  ${Desktop} {
                    display: block;
                  }
                `}
              >
                <img
                  src={post.thumbnail.file.url}
                  css={css`
                    border: 1px solid black;

                    display: block;
                    width: 100%;
                    height: auto;
                    min-width: 280px;
                    max-width: 400px;
                    ${Tablet} {
                      min-width: none;
                      max-width: none;
                      width: 100%;
                      height: 200px;
                    }
                    box-sizing: content-box;
                    object-fit: cover;
                  `}
                />
                <div
                  css={css`
                    padding: 15px 0;
                    ${Desktop} {
                      padding: 20px 0;
                    }
                  `}
                >
                  <h2
                    css={css`
                      font-weight: bold;
                      display: block;
                      margin-bottom: 4px;
                      line-height: 1.2;
                      text-align: left;
                    `}
                  >
                    {post.title}
                  </h2>
                  <h3
                    css={css`
                      display: block;
                      font-weight: 500;
                      ${Tablet} {
                        margin-bottom: 10px;
                      }

                      ${Desktop} {
                        margin-top: 5px;
                        margin-bottom: 15px;
                      }
                    `}
                  >
                    {post.date}
                  </h3>
                  <p>{post.description.description}</p>
                </div>
              </Link>
            );
          })}
          <div // Empty card with zero height
            css={css`
              color: transparent;
              width: 100%;
              height: 0;

              ${Tablet} {
                width: 340px;
              }
              display: ${all ? "block" : "none"};
            `}
          />
        </div>
        <div
          css={css`
            margin-left: auto;
            margin-right: auto;
            width: 200px;
          `}
        >
          <Link to="/blog">
            <div
              css={css`
                display: ${all ? " none" : "flex"};
                align-items: center;
                user-select: none;
                margin: 0 auto;
                margin-bottom: 60px;
                width: 160px;
                height: 45px;
                border: 2px solid black;
                border-radius: 10px;
                justify-content: center;
                color: black;
                cursor: pointer;
                transition: all 0.2s ease;
                ${Tablet} {
                  width: 200px;
                  margin-bottom: 120px;
                }
                ${Desktop} {
                  height: 50px;
                  margin-bottom: 180px;
                }

                svg {
                  path {
                    fill: black;
                    transition: all 0.2s ease;
                  }
                }
                &:hover {
                  background: black;
                  color: white;
                  svg {
                    path {
                      fill: white;
                    }
                  }
                }
              `}
            >
              <h3>
                <FormattedHTMLMessage id="blog.more" />
              </h3>

              <RightArrow
                css={css`
                  height: 10px;
                  margin-left: 5px;
                  ${Tablet} {
                    height: 12px;
                  }
                  ${Desktop} {
                    height: 15px;
                    margin-left: 10px;
                  }
                `}
              />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlogList;
